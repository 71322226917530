import React from 'react';
import { ActionButton, Footer, Layout, Navbar, Sponsor } from '@components';
import { Col, Container, NavDropdown, ResponsiveEmbed, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { groupBy, orderBy } from 'lodash';
import '@scss/archive-page.scss';

const ArchivePage = ({ data }) => {
  const { allJudgingSessionsList, mainStagePresentation, speakers } = data.postgres;
  const renderJudging = () => {
    const categories = groupBy(orderBy(allJudgingSessionsList, `id`), `type`);

    return <>
      <NavDropdown.ItemText>Senior Design</NavDropdown.ItemText>
      {categories.SENIOR_DESIGN.map(session =>
        <NavDropdown.Item href={`/judging/${session.id}`}>{session.topic}</NavDropdown.Item>)}
      <NavDropdown.Divider />
      <NavDropdown.Item href={`/judging/${categories.RESEARCH_SYMPOSIUM[0].id}`}>Research Symposium</NavDropdown.Item>
    </>;
  };

  return <Layout className="archive-page">
    <Navbar year={mainStagePresentation.year} monochrome />
    <div className="archive-banner">
      <h1 style={{ textAlign: `center` }}>IT EXPO {mainStagePresentation.year}</h1>
      <Row className="justify-content-center">
        <Col md={6}>
          <ResponsiveEmbed aspectRatio="16by9" className="mr-md-2 mb-3">
            <iframe
              title={`IT EXPO Main Stage Presentation ${mainStagePresentation.year}`}
              src={mainStagePresentation.videoUrl}
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen />
          </ResponsiveEmbed>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p style={{
            color: `white`, fontFamily: `Poppins, sans-serif`,
            fontWeight: `500`, margin: `20px`, textAlign: `center`,
          }} className="tagline">Where People Meet | Where Ideas Evolve | Where the Future is Made</p>
        </Col>
      </Row>
    </div>

    <div className="action-buttons">
      <Container>
        <Row className="justify-content-center mb-3">
          <a href={`/${mainStagePresentation.year}/seniors`} rel="noopener noreferrer">
            <ActionButton className="button">Senior Design</ActionButton>
          </a>
          <a href={`/${mainStagePresentation.year}/high-school`} rel="noopener noreferrer">
            <ActionButton className="button">High School</ActionButton>
          </a>
        </Row>
        <Row className="justify-content-center">
          <a href={`/${mainStagePresentation.year}/research`} rel="noopener noreferrer">
            <ActionButton className="button">Research symposium</ActionButton>
          </a>
          {allJudgingSessionsList.length !== 0 &&
            <ActionButton className="button">
              <NavDropdown title="Judging">
                {renderJudging()}
              </NavDropdown>
            </ActionButton>}
        </Row>
      </Container>
    </div>
    <div className="schedule">
      <div className="title">
        <h2><span>Our Speakers</span></h2>
      </div>
      <div>
        <Col sm={6} md={4} xl>
          <div className="speaker">
            <Row className="speaker-row justify-content-center">
              {speakers.map(speaker =>
                <div key={speaker.id}>
                  <GatsbyImage image={getImage(speaker.imageFile)} alt={speaker.name} />
                  <div className="details">
                    <p className="date">{speaker.time}</p>
                    <h3 className="name">{speaker.name}</h3>
                    <p className="speaker-title" style={{ overflowWrap: `break-word` }}>{speaker.title}</p>
                    <p className="company">{speaker.company}</p>
                  </div>
                </div>)}
            </Row>
          </div>
        </Col>
      </div>
    </div>
    <Sponsor year={mainStagePresentation.year} />
    <Footer year={mainStagePresentation.year} />
  </Layout>;
};

export const query = graphql`
query archivePageQuery($year: String!) {
  postgres {
    mainStagePresentation: mainStagePresentationByYear(year: $year) {
      id
      videoUrl
      year
    }
    speakers: allSpeakersList(condition: {year: $year}) {
      id
      name
      image
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 200)
        }
      }
      time
      title
      company
      year
    }
    allJudgingSessionsList(condition: {year: $year}) {
      topic
      year
      type
      id
    }
  }
}
`;

export default ArchivePage;
